// https://www.youtube.com/watch?v=8y9wUwJvdGs
// https://codesandbox.io/s/xstate-vue-3-template-vrkk9

import { createMachine } from "xstate";

// export const taskDocumentStateMachine = createMachine({
export const createTaskDocumentStateMachine = (role) => {
    return createMachine({
        id: "paper-workflow",
        initial: "requested",
        context: {
            role
        },
        states: {
            requested: {
                on: {
                    PREPARE: {
                        target: "prepared",
                        cond: (cxt) => {
                            return cxt.role == 'PREPARER';
                        },
                    },
                },
            },
            prepared: {
                on: {
                    RECALL: {
                        target: "requested",
                        cond: (cxt) => {
                            return cxt.role == 'PREPARER';
                        },
                    },
                    DECLINE: {
                        target: "rejected",
                        cond: (cxt) => {
                            return cxt.role == 'REVIEWER';
                        },
                    },
                    ACCEPT: {
                        target: "reviewed",
                        cond: (cxt) => {
                            return cxt.role == 'REVIEWER';
                        },
                    },
                },
            },
            reviewed: {
                on: {
                    REJECT: {
                        target: "rejected",
                        cond: (cxt) => {
                            return cxt.role == 'APPROVER';
                        },
                    },
                    APPROVE: {
                        target: "approved",
                        cond: (cxt) => {
                            return cxt.role == 'APPROVER';
                        },
                    },
                },
            },
            rejected: {
                on: {
                    RETRY: {
                        target: "prepared",
                        cond: (cxt) => {
                            return cxt.role == 'PREPARER';
                        },
                    },
                },
            },
            approved: {
                type: "final",
            },
        },
    });
};
